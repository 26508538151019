import { WorkspaceSettingsPage } from '@/enums/WorkspaceSettingsPage';
import { AddonId } from '@/models/Addon';
import { BuildCacheToolIds } from '@/models/BuildCacheTool'; /* eslint-disable no-param-reassign */
import ModernPlanType from '@/pages/subscription/types/ModernPlanType';

/* eslint-disable no-param-reassign */
type Config = {
  anchor?: string;
  format?: string;
  params?: any;
  anchorParams?: any;
};

export const pathWithConfig = (path: string, config?: Config): string => {
  if (!config) {
    return path;
  }

  if (config.format) {
    path += `.${config.format}`;
  }

  if (config.params) {
    const keyValuePairs = Object.entries(config.params)
      .filter(([, val]) => Boolean(val))
      .map(([key, val]) => `${key}=${encodeURIComponent(val as string)}`)
      .join('&');

    if (keyValuePairs.length) {
      path += `?${keyValuePairs}`;
    }
  }

  if (config.anchor) {
    path += `#${config.anchor}`;
  }

  if (config.anchorParams) {
    const keyValuePairs = Object.entries(config.anchorParams)
      .filter(([, val]) => Boolean(val))
      .map(([key, val]) => `${key}=${encodeURIComponent(val as string)}`)
      .join('&');

    if (keyValuePairs.length) {
      path += `?${keyValuePairs}`;
    }
  }

  return path;
};

export const basePathForApp = (appSlug: string): string => {
  return `/app/${appSlug}`;
};

export const basePathForOrganization = (slug: string): string => {
  if (!slug) throw new Error('Invalid organization slug.');

  return `/organization/${slug}`;
};

export const basePathForAccount = (accountType: string, accountSlug?: string): string => {
  if (!accountType) throw new Error('No account type');

  switch (accountType.toLowerCase()) {
    case 'user':
      return '/me/profile';
    case 'organization':
      if (!accountSlug) throw new Error('Invalid organization slug.');

      return basePathForOrganization(accountSlug);
    default:
      throw new Error('Invalid account type');
  }
};

export const dashboardPath = (config?: Config): string => {
  return pathWithConfig('/dashboard', config);
};

export const buildCachePath = (config?: Config): string => {
  return pathWithConfig('/build-cache', config);
};

export const buildCacheConnectionPath = (workspaceSlug: string, config?: Config): string => {
  return pathWithConfig(`/build-cache/${workspaceSlug}/connections/new`, config);
};

export const buildCacheDeletePath = (workspaceSlug: string, config?: Config): string => {
  return pathWithConfig(`/build-cache/${workspaceSlug}/entries`, config);
};

export const releaseManagementStoreCredentialsPath = (workspaceSlug: string, appSlug: string): string => {
  return `/release-management/workspaces/${workspaceSlug}/apps/${appSlug}/store-credentials`;
};

export const releaseManagementConnectedAppsPagePath = (
  workspaceSlug: string,
  params?: Record<string, string>,
): string => {
  return pathWithConfig(`/release-management/workspaces/${workspaceSlug}/connected-apps`, {
    params,
  });
};

export const releaseManagementConnectedAppPagePath = (workspaceSlug: string, connectedAppId: string): string => {
  return `/release-management/workspaces/${workspaceSlug}/connected-apps/${connectedAppId}`;
};

export const releaseManagementConnectedAppPresetsPagePath = (workspaceSlug: string, connectedAppId: string): string => {
  return `${releaseManagementConnectedAppPagePath(workspaceSlug, connectedAppId)}/presets`;
};

export const releaseManagementConnectedAppSettingsPagePath = (
  workspaceSlug: string,
  connectedAppId: string,
): string => {
  return `${releaseManagementConnectedAppPagePath(workspaceSlug, connectedAppId)}/settings`;
};

export const releaseManagementConnectedAppFeatureFlagsPagePath = (
  workspaceSlug: string,
  connectedAppId: string,
): string => {
  return `${releaseManagementConnectedAppPagePath(workspaceSlug, connectedAppId)}/feature-flags`;
};

export const releaseManagementConnectedAppInstallableArtifactsPath = (
  workspaceSlug: string,
  connectedAppId: string,
): string => {
  return `${releaseManagementConnectedAppPagePath(workspaceSlug, connectedAppId)}/installable-artifacts`;
};

export const releaseManagementConnectedAppInstallableArtifactPath = (
  workspaceSlug: string,
  connectedAppId: string,
  artifactSlug: string,
) => {
  return `${releaseManagementConnectedAppInstallableArtifactsPath(workspaceSlug, connectedAppId)}/${artifactSlug}`;
};

export const releaseManagementConnectedAppReleaseManagersPagePath = (
  workspaceSlug: string,
  connectedAppId: string,
): string => {
  return `${releaseManagementConnectedAppPagePath(workspaceSlug, connectedAppId)}/release-managers`;
};

export const releaseManagementCreateReleasePagePath = (workspaceSlug: string, connectedAppId: string): string => {
  return `${releaseManagementConnectedAppPagePath(workspaceSlug, connectedAppId)}/create-new-release`;
};

export const releaseManagementConnectedAppReleasePagePath = (
  workspaceSlug: string,
  connectedAppId: string,
  releaseId: string,
): string => {
  return pathWithConfig(
    `${releaseManagementConnectedAppPagePath(workspaceSlug, connectedAppId)}/releases/${releaseId}`,
  );
};

export const releaseManagementConnectedAppStagePath = (
  workspaceSlug: string,
  connectedAppId: string,
  releaseId: string,
  stageName: string,
) => `${releaseManagementConnectedAppReleasePagePath(workspaceSlug, connectedAppId, releaseId)}/stages/${stageName}`;

export const releaseManagementConnectedAppConfigurationPath = (
  workspaceSlug: string,
  connectedAppId: string,
  releaseId: string,
  tab?: string,
) =>
  pathWithConfig(
    `${releaseManagementConnectedAppReleasePagePath(workspaceSlug, connectedAppId, releaseId)}/stages/configuration`,
    {
      params: tab && { tab },
    },
  );

export const getGoogleServiceCredentialsPath = (workspaceSlug: string) =>
  `/workspaces/${workspaceSlug}/google-service-credentials`;

export const deleteGoogleServiceCredentialPath = (workspaceSlug: string, credentialId: number | string) =>
  `/workspaces/${workspaceSlug}/google-service-credentials/${credentialId}`;

export const insightsPath = (accountSlug: string, config?: Config): string => {
  return pathWithConfig(`/insights/${accountSlug}/overview`, config);
};

export const insightsExploreBuildsPath = (accountSlug: string, config?: Config): string =>
  pathWithConfig(`/insights/${accountSlug}/explore/builds`, config);

export const insightsExploreUtilizationPath = (accountSlug: string, config?: Config): string =>
  pathWithConfig(`/insights/${accountSlug}/explore/utilization`, config);

export const insightsExploreTestsPath = (accountSlug: string, config?: Config): string =>
  pathWithConfig(`/insights/${accountSlug}/explore/tests`, config);

export const gettingStartedBitriseCIPath = (config?: Config): string => {
  return pathWithConfig('/getting-started-bitrise-ci', config);
};

export const addNewAppPath = (config?: Config): string => {
  return pathWithConfig('/apps/add', config);
};

export const appSettingsPath = (appSlug: string, subPath?: string, config?: Config): string => {
  let settingsPath = `/app/${appSlug}/settings`;
  settingsPath = subPath ? `${settingsPath}/${subPath}` : settingsPath;
  return pathWithConfig(settingsPath, config);
};

export const appSettingsIntegrationsPagePath = (
  appSlug: string,
  tab?: 'stores' | 'git-provider' | 'webhooks',
): string => {
  return pathWithConfig(appSettingsPath(appSlug, 'integrations'), {
    params: { tab },
  });
};

export const settingsPath = (accountType: string, accountSlug?: string, config?: Config): string => {
  return pathWithConfig(basePathForAccount(accountType, accountSlug), config);
};

export const billingPath = (accountType: string, accountSlug?: string, config?: Config): string => {
  if (!config) {
    config = {};
  }
  config.anchor = 'billing';

  return pathWithConfig(basePathForAccount(accountType, accountSlug), config);
};

export const editAccountPath = (accountType: string, accountSlug?: string, config?: Config): string => {
  if (!config) {
    config = {};
  }
  config.anchor = 'edit_profile';

  return pathWithConfig(basePathForAccount(accountType, accountSlug), config);
};

export const userCancellationReasonsPath = (config?: Config): string => {
  return pathWithConfig('/me/account_cancellation_reasons', config);
};

export const userSecurityPagePath = (config?: Config): string => {
  return pathWithConfig('/me/account/security', config);
};

export const organizationCancellationReasonsPath = (accountSlug: string, config?: Config): string => {
  return pathWithConfig(`/organizations/${accountSlug}/account_cancellation_reasons`, config);
};

export const sendSubscriptionCancelReasonPath = (
  accountType: string,
  accountSlug?: string,
  config?: Config,
): string => {
  return pathWithConfig(`${basePathForAccount(accountType, accountSlug)}/send_subscription_cancel_reason`, config);
};

export const sendAccountDeleteReasonPath = (accountType: string, accountSlug?: string, config?: Config): string => {
  return pathWithConfig(`${basePathForAccount(accountType, accountSlug)}/send_account_delete_reason`, config);
};

export const ownedAppsPath = (accountType: string, accountSlug?: string, config?: Config): string => {
  if (!config) {
    config = {
      format: 'json',
    };
  }

  if (accountType === 'User') {
    config.params = {
      user_role: 'owner',
      only_direct: true,
    };
  } else {
    config.params = {
      organization_slug: accountSlug,
    };
  }

  return pathWithConfig('/apps', config);
};

export const ownedOrganizationsPath = (config?: Config): string => {
  if (!config) {
    config = {
      format: 'json',
    };
  }

  config.params = {
    only_single_confirmed_owner: true,
  };

  return pathWithConfig('/me/profile/organizations', config);
};

export const accountDeletePath = (accountType: string, accountSlug?: string, config?: Config): string => {
  if (!config) {
    config = {
      format: 'json',
    };
  }

  if (accountType === 'User') {
    return pathWithConfig('/users', config);
  }

  return pathWithConfig(basePathForAccount(accountType, accountSlug), config);
};

export const newCreditSubscriptionPath = (organizationSlug: string, plan?: ModernPlanType): string => {
  return `/organization/${organizationSlug}/credit_subscription/new${plan ? `?plan=${plan}` : ''}`;
};

export const editCreditSubscriptionPath = (organizationSlug: string, plan: ModernPlanType): string => {
  return `/organization/${organizationSlug}/credit_subscription/edit?plan=${plan}`;
};

export const planSelectorPagePath = (organizationSlug: string): string => {
  return `/organization/${organizationSlug}/credit_subscription/plan_selector_page`;
};

export const manageExceededLimitsPagePath = (organizationSlug: string): string => {
  return `/organization/${organizationSlug}/limits-exceeding-downgrade`;
};

export const addOnCheckoutPagePath = (organizationSlug: string): string => {
  return `/organization/${organizationSlug}/credit_subscription/add_ons_page`;
};

export const workflowEditorPath = (appSlug: string, workflowId?: string | null): string => {
  return pathWithConfig(`/app/${appSlug}/workflow_editor`, {
    anchor: '!/workflows',
    anchorParams: workflowId ? { workflow_id: workflowId } : null,
  });
};

export const workflowEditorPathWithConfig = (appSlug: string, config?: Config): string => {
  return pathWithConfig(`/app/${appSlug}/workflow_editor`, {
    anchor: '!/workflows',
    params: config?.params,
    format: config?.format,
    anchorParams: config?.anchorParams,
  });
};

export const workflowEditorYmlPath = (appSlug: string, config?: Config): string => {
  return pathWithConfig(`/app/${appSlug}/workflow_editor`, {
    anchor: '!/yml',
    params: config?.params,
    format: config?.format,
  });
};

export const workflowEditorStackPath = (appSlug: string): string => {
  return pathWithConfig(`/app/${appSlug}/workflow_editor`, { anchor: '!/stack' });
};

export const disabledPath = (appSlug: string): string => {
  return `/app/${appSlug}/disabled.json`;
};

export const supportAccessPath = (repositorySlug: string): string => {
  return `/app/${repositorySlug}/support_access.json`;
};

export const startBuildPath = (appSlug: string): string => {
  return `/app/${appSlug}/build/start.json`;
};

export const triggerNowPath = (scheduleId: string): string => {
  return `/api/schedule/${scheduleId}/trigger.json`;
};

export const abortBuildPath = (buildSlug: string): string => {
  return `/build/${buildSlug}/abort.json`;
};

export const abortPipelinePath = (appSlug: string, pipelineSlug: string): string => {
  return `/app/${appSlug}/pipelines/${pipelineSlug}/abort`;
};

export const bitriseYMLPath = (buildSlug: string): string => {
  return `/build/${buildSlug}/bitrise_yml`;
};

export const currentBitriseYMLPath = (appSlug: string): string => {
  return `/api/app/${appSlug}/config.yml`;
};

export const restoreBitriseYMLPath = (appSlug: string): string => {
  return `/api/app/${appSlug}/config.json`;
};

export const installableArtifactsPath = (appSlug: string, searchParams?: URLSearchParams) => {
  const queryParam = searchParams ? `?${searchParams}` : '';
  return `${basePathForApp(appSlug)}/installable-artifacts${queryParam}`;
};

export const installableArtifactPath = (appSlug: string, artifactSlug: string) => {
  return `${basePathForApp(appSlug)}/installable-artifacts/${artifactSlug}`;
};

export const installableArtifactPrivateInstallUrlPath = (appSlug: string, artifactSlug: string) => {
  return `${basePathForApp(appSlug)}/installable-artifacts/${artifactSlug}/private-install-url`;
};

export const togglePublicInstallPagePath = (artifactSlug: string): string => {
  return `/artifact/${artifactSlug}/is_public_page_enabled.json`;
};

export const buildLogPath = (buildSlug: string): string => {
  return `/build/${buildSlug}/logs`;
};

export const buildDetailsPagePath = (buildSlug: string, tab?: string): string => {
  return pathWithConfig(`/build/${buildSlug}`, {
    params: tab
      ? {
          tab,
        }
      : null,
  });
};

export const pipelineDetailsPagePath = (appSlug: string | undefined, pipelineId: string, tab?: string): string => {
  if (!appSlug || !pipelineId) {
    return '#';
  }

  return pathWithConfig(`/app/${appSlug}/pipelines/${pipelineId}`, {
    params: tab ? { tab } : null,
  });
};

export const pipelineAttemptDetailsPagePath = (
  appSlug: string | undefined,
  pipelineId: string,
  attemptId?: string,
  tab?: string,
): string => {
  if (!appSlug || !pipelineId) {
    return '#';
  }

  if (!attemptId) {
    return pipelineDetailsPagePath(appSlug, pipelineId);
  }

  return pathWithConfig(`/app/${appSlug}/pipelines/${pipelineId}/attempts/${attemptId}`, {
    params: tab ? { tab } : null,
  });
};

export const pipelineTestResultsPath = (appSlug: string, pipelineId: string, attemptId: string): string => {
  if (!appSlug || !pipelineId || !attemptId) {
    return '#';
  }

  return `${pipelineAttemptDetailsPagePath(appSlug, pipelineId, attemptId)}/test-results`;
};

export const getArtifactPathForBuild = (buildSlug?: string): string => {
  if (!buildSlug) {
    return '';
  }
  return `/build/${buildSlug}/artifacts.json`;
};

export const getArtifactPathForPipeline = (appSlug?: string, pipelineSlug?: string, attemptId?: string): string => {
  if (!appSlug || !pipelineSlug) {
    return '';
  }
  return attemptId
    ? `/app/${appSlug}/pipelines/${pipelineSlug}/attempts/${attemptId}/artifacts.json`
    : `/app/${appSlug}/pipelines/${pipelineSlug}/artifacts.json`;
};

export const buildInsightsTokenPath = (config?: Config): string => {
  return pathWithConfig('/insights_token', config);
};

export const appPipelineConfigPath = (appSlug: string): string => {
  return `/app/${appSlug}/pipeline_config`;
};

export const appDeletePath = (appSlug: string) => {
  return `/app/${appSlug}/destroy.json`;
};

export const signUpPath = (): string => {
  return '/users/sign_up';
};

export const logInPath = (): string => {
  return '/users/sign_in';
};

export const appPath = (appSlug: string): string => {
  return `/api/app/${appSlug}`;
};

export const appWorkflowsPath = (appSlug: string): string => {
  return `/api/app/${appSlug}/workflows`;
};

export const appPipelinesPath = (appSlug: string): string => {
  return `/app/${appSlug}/pipelines`;
};

export const appleDeveloperAccountTestPath = (appSlug: string): string =>
  `/app/${appSlug}/apple_developer_account/connection_status`;

export const appleIdConnectPath = () => '/apple-id-connection/connect';

export const appleIdSelectDevicePath = () => '/apple-id-connection/select-device';

export const appleIdVerifyCodePath = () => '/apple-id-connection/verify-code';

export const projectTypePath = (appSlug: string): string => {
  return `/app/${appSlug}/project_type`;
};

export const transferOwnershipPath = (appSlug: string): string => {
  return `/app/${appSlug}/team/transfer_ownership`;
};

export const transferOwnershipTargetAccounts = (appSlug: string): string => {
  return `/app/${appSlug}/transfer-ownership`;
};

export const accountMetadataPath = (): string => '/me/profile/metadata.json';

export const appAvatarPath = (appSlug: string): string => {
  return `/app/${appSlug}/avatar_url`;
};

export const appAvatarEndedPath = (appSlug: string): string => {
  return `${appAvatarPath(appSlug)}/upload_ended`;
};

export const addTeamMemberPath = (appSlug: string) => {
  return `/app/${appSlug}/team/create`;
};

export const changeRolePath = (appSlug: string) => {
  return `/app/${appSlug}/team/change_role`;
};

export const reinviteUserPath = (appSlug: string) => {
  return `/app/${appSlug}/team/reinvite`;
};

export const removeTeamMemberPath = (appSlug: string, userId?: number) => {
  const queryParam = userId === undefined ? '' : `?user_id=${userId}`;
  return `/app/${appSlug}/team/remove${queryParam}`;
};

export const removeGroupPath = (appSlug: string, groupSlug?: string) => {
  const queryParam = groupSlug ? `?group_slug=${groupSlug}` : '';
  return `/app/${appSlug}/team/remove_group${queryParam}`;
};

export const addTeamGroupPath = (appSlug: string) => {
  return `/app/${appSlug}/team/add_group`;
};

export const getTestDevicesPath = (appSlug: string) => {
  return `/app/${appSlug}/settings/team-test-devices.json`;
};

export const getTestDevicesPagePath = (appSlug: string) => {
  return `/app/${appSlug}/settings/test-devices`;
};

export const teamMemberUnsubscribePath = (appSlug: string) => {
  return `/app/${appSlug}/team/unsubscribe`;
};

export const getTeamForAppPath = (appSlug: string) => {
  return `/app/${appSlug}/get_teams`;
};

export const toggleRollingBuildsPath = (appSlug: string): string => `/app/${appSlug}/addon/addons-rolling-build`;

export const rollingBuildsTypesPath = (appSlug: string): string => `/app/${appSlug}/rolling_builds_toggle`;

export const userAvatarPath = (userSlug: string, fileName: string | null): string | undefined =>
  fileName
    ? `https://concrete-userfiles-production.s3.us-west-2.amazonaws.com/users/${userSlug}/avatar/${fileName}`
    : undefined;

export const organizationAvatarPath = (organizationSlug: string) => {
  return `/organization/${organizationSlug}/avatar_url`;
};

export const organizationAvatarEndedPath = (organizationSlug: string) => {
  return `/organization/${organizationSlug}/avatar_url/upload_ended`;
};

export const organizationDefaultAvatar = (organizationSlug: string) => {
  return `/organization/${organizationSlug}/default_avatar_url`;
};

export const profileAvatarPath = () => {
  return `/me/profile/avatar_url`;
};

export const profileAvatarEndedPath = () => {
  return `/me/profile/avatar_url/upload_ended`;
};

export const selectiveBuildsPathsToBuild = (appSlug: string): string => `/app/${appSlug}/paths_to_build`;

export const checkGitServiceConnectionPath = (appSlug: string): string =>
  `/app/${appSlug}/check-git-service-connection.json`;

export const checkGitHubAppRepositoryConnectionHealthPath = (appSlug: string) =>
  `/app/${appSlug}/github_app_installation/connection_health`;

export const selectiveBuildsEnabledPath = (appSlug: string): string => `/app/${appSlug}/selective_builds_enabled`;

export const manualApprovalEnabledPath = (appSlug: string): string => `/app/${appSlug}/manual_approval_enabled`;

export const buildCacheProjectFilterValuesPath = (workspaceSlug: string, buildTool: string, config?: Config) => {
  return pathWithConfig(`/build-cache/${workspaceSlug}/invocations/${buildTool}/projects.json`, config);
};

export const buildCacheCiProviderFilterValuesPath = (workspaceSlug: string, buildTool: string, config?: Config) => {
  return pathWithConfig(`/build-cache/${workspaceSlug}/invocations/${buildTool}/ci-providers.json`, config);
};

export const buildCacheWorkflowNameFilterValuesPath = (workspaceSlug: string, buildTool: string, config?: Config) => {
  return pathWithConfig(`/build-cache/${workspaceSlug}/invocations/${buildTool}/workflows.json`, config);
};

export const subscribeToBuildToolWaitlistPath = (workspaceSlug: string, config?: Config) => {
  return pathWithConfig(`/build-cache/${workspaceSlug}/subscribe-to-build-tool-waitlist.json`, config);
};

export const appBuildCachesPath = (appSlug: string) => `/app/${appSlug}/build-caches`;

export const appBuildCachePath = (appSlug: string) => `/app/${appSlug}/build-cache`;

export const downloadBuildCachePath = (appSlug: string, theCacheItemKey: string) =>
  `${appBuildCachePath(appSlug)}/download?cache_item_key=${theCacheItemKey}`;

export const keyValueCacheItemsPath = (appSlug: string) => `/v0.1/apps/${appSlug}/cache-items`;

export const keyValueCacheItemResourcePath = (appSlug: string, cacheId: string) =>
  `${keyValueCacheItemsPath(appSlug)}/${cacheId}`;

export const keyValueCacheItemDownloadPath = (appSlug: string, cacheId: string) =>
  `${keyValueCacheItemResourcePath(appSlug, cacheId)}/download`;

export const nextBuildNumberPath = (appSlug: string) => `/app/${appSlug}/next_build_number`;

export const hasBitriseYmlPath = (buildSlug: string) => `/build/${buildSlug}/has_bitrise_yml`;

export const regenerateBuildTokenPath = (appSlug: string) => `/app/${appSlug}/regenerate_build_trigger_token`;

export const gitHubChecksEnabledPath = (appSlug: string) => `/app/${appSlug}/github_checks_enabled`;

export const updateEmailNotificationsPath = (appSlug: string) => `/app/${appSlug}/update_email_notifications.json`;

export const hasRegisteredWebhookPath = (appSlug: string) => `/app/${appSlug}/has-registered-webhook`;

export const registerWebhookPath = (appSlug: string) => `/app/${appSlug}/register-webhook`;

export const addonPath = (appSlug: string, addonId: AddonId) => `/app/${appSlug}/addon/${addonId}`;

export const testingAddonLoginPath = (appSlug: string, buildSlug: string) =>
  `${addonPath(appSlug, AddonId.TestReports)}/login_page?build_slug=${buildSlug}`;

export const addonListPath = (appSlug: string) => `/v0.1/apps/${appSlug}/addons`;

export const generateSshKeysPath = () => `/utilities/ssh_keygen.json`;

export const registerSshKeysPath = (appSlug: string) => `/app/${appSlug}/register-ssh-keys.json`;

export const removeSshKeysPath = (appSlug: string) => `/app/${appSlug}/remove-ssh-keys`;

export const saveGitHttpCredsPath = (appSlug: string) => `/app/${appSlug}/git-http-credentials.json`;

export const appWatchPath = (appSlug: string) => `/app/${appSlug}/watch`;

export const renewUserAuthTokenPath = () => `/me/profile/security/create_website_user_auth_token`;

export const latestBuildPath = (appSlug: string) => `/v0.1/apps/${appSlug}/builds?limit=1&sort_by=running_first`;

export const creditSurveyPath = (workspaceSlug: string) => `/workspaces/${workspaceSlug}/credit_survey`;

export const activateBuildCacheTrialPath = (workspaceSlug: string) => `/workspaces/${workspaceSlug}/build_cache_trial`;

export const deactivateBuildCacheTrialPath = (workspaceSlug: string) =>
  `/workspaces/${workspaceSlug}/build_cache_trial`;

export const buildCacheSubscriptionPath = (workspaceSlug: string) =>
  `/workspaces/${workspaceSlug}/build_cache_subscription`;

export const activateBuildCacheSubscriptionEventPath = (workspaceSlug: string) =>
  `/workspaces/${workspaceSlug}/build_cache_flows/activation.json`;

export const buildCacheRequestSupportPath = (workspaceSlug: string) =>
  `/workspaces/${workspaceSlug}/build_cache_flows/support_request.json`;

export const buildPerformancePath = (buildSlug: string) => `/build/${buildSlug}/performance`;

export const buildPerformanceTabPath = (workspaceSlug: string, buildSlug: string) =>
  `/workspaces/${workspaceSlug}/build_cache_flows/builds/${buildSlug}/performance_tab`;

export const buildToolInvocationStepExecutionPath = (
  workspaceSlug: string,
  buildTool: string,
  invocationId: string,
  stepExecutionId: string,
) => `/build-cache/${workspaceSlug}/invocations/${buildTool}/${invocationId}/step-executions/${stepExecutionId}`;

export const buildToolInvocationTaskListPath = (
  workspaceSlug: string,
  invocationId: string,
  config: Omit<Config, 'format'> & { format: 'json' | 'csv' },
) => {
  return pathWithConfig(`/build-cache/${workspaceSlug}/invocations/gradle/${invocationId}/tasks`, config);
};

export const buildToolInvocationTargetListPath = (
  workspaceSlug: string,
  invocationId: string,
  config: Omit<Config, 'format'> & { format: 'json' | 'csv' },
) => {
  return pathWithConfig(`/build-cache/${workspaceSlug}/invocations/bazel/${invocationId}/targets`, config);
};

export const buildCacheInvocationListPath = (workspaceSlug: string, filterParams?: string) => {
  const queryParam = filterParams ? `?${filterParams}` : '';
  return `/build-cache/${workspaceSlug}/invocations.json${queryParam}`;
};

export const buildToolInvocationPath = (
  workspaceSlug: string,
  invocationId: string,
  buildTool: string,
  config?: Config,
): string => {
  return pathWithConfig(`/build-cache/${workspaceSlug}/invocations/${buildTool}/${invocationId}`, config);
};

export const cacheActivitiesPath = (workspaceSlug: string, filterParams?: string) => {
  const queryParam = filterParams ? `?${filterParams}` : '';
  return `/build-cache/${workspaceSlug}/cache_operations.json${queryParam}`;
};

export const cacheActivityProjectFilterValuesPath = (
  workspaceSlug: string,
  buildTool: BuildCacheToolIds,
  config?: Config,
) => {
  return pathWithConfig(`/build-cache/${workspaceSlug}/cache_operations/${buildTool}/projects.json`, config);
};

export const cacheActivityWorkflowNameFilterValuesPath = (
  workspaceSlug: string,
  buildTool: BuildCacheToolIds,
  config?: Config,
) => {
  return pathWithConfig(`/build-cache/${workspaceSlug}/cache_operations/${buildTool}/workflows.json`, config);
};

export const buildCacheExpandStoragePath = (workspaceSlug: string) =>
  `/build-cache/${workspaceSlug}/build-cache-expand-storage.json`;

export const buildTestResultsPath = (buildSlug: string) => `/build/${buildSlug}/test_results`;

export const buildPerformanceCacheInfo = (buildSlug: string) => `/build/${buildSlug}/performance/cache_info`;

const workspaceSettingsRootPath = (workspaceSlug: string) => `/workspaces/${workspaceSlug}/settings`;

export const workspaceSettingsPath = (workspaceSlug: string, page?: WorkspaceSettingsPage) => {
  let path = workspaceSettingsRootPath(workspaceSlug);
  if (page) path += `/${page}`;
  return path;
};

export const workspaceSettingsBillingReleaseManagementPlanPath = (workspaceSlug: string) =>
  `/workspaces/${workspaceSlug}/settings/billing/release-management-plan`;

export const getWorkspaceTeamPath = (workspaceSlug: string, config?: Config): string => {
  return pathWithConfig(`/workspaces/${workspaceSlug}/settings/team`, config);
};

export const getWorkspaceBillingPath = (workspaceSlug: string, config?: Config): string => {
  return pathWithConfig(`/workspaces/${workspaceSlug}/settings/billing`, config);
};

export const getWorkspaceUpgradeInsightsPath = (workspaceSlug: string, config?: Config): string => {
  return pathWithConfig(`/organization/${workspaceSlug}/credit_subscription/add_ons_page`, config);
};

export const getTimeBasedTrialStats = (workspaceSlug: string, config?: Config): string => {
  return pathWithConfig(`/api/organizations/${workspaceSlug}/trial_stats`, config);
};

export const getExtendLimitPath = (workspaceSlug: string): string => {
  return `/api/organizations/${workspaceSlug}/extend_limits`;
};

export const getWorkspaceSettingsPath = (workspaceSlug: string, config?: Config): string => {
  return pathWithConfig(`/workspaces/${workspaceSlug}/settings/overview`, config);
};

export const getBetaTagsPath = (workspaceSlug: string, config?: Config): string => {
  return pathWithConfig(`/organization/${workspaceSlug}/beta_tags`, config);
};

export const scheduleBuildPath = (appSlug: string, config?: Config): string => {
  return pathWithConfig(`/api/schedule/${appSlug}.json`, config);
};

export const getScheduledNextRunPath = (appSlug: string, config?: Config): string => {
  return pathWithConfig(`/api/schedule/${appSlug}/next_run.json`, config);
};

export const scheduledBuildPath = (scheduleBuildId: string, config?: Config): string => {
  return pathWithConfig(`/api/schedule/${scheduleBuildId}.json`, config);
};

export const exceededLimitsPath = (workspaceSlug: string, config?: Config): string => {
  return pathWithConfig(`/workspaces/${workspaceSlug}/limits-exceeding-downgrade`, config);
};

export const createFileStoragePath = (appSlug: string): string => {
  return `/api/app/${appSlug}/project_file_storage_document/create.json`;
};

export const uploadEndedFileStoragePath = (fileId: string | number): string => {
  return `/project_file_storage_document/${fileId}/upload_ended.json`;
};

export const createCertificatePath = (appSlug: string): string => {
  return `/api/app/${appSlug}/build_certificate/create.json`;
};

export const uploadEndedCertificatePath = (id: string | number): string => {
  return `/build_certificate/${id}/upload_ended.json`;
};

export const previewCertificatePath = (): string => {
  return '/build_certificate/preview';
};

export const passwordCertificatePath = (id: string | number): string => {
  return `/build_certificate/${id}/password.json`;
};

export const detailsCertificatePath = (id: string | number): string => {
  return `/build_certificate/details/${id}`;
};

export const certificatesPath = (appSlug: string): string => {
  return `/api/app/${appSlug}/build_certificate/show.json`;
};

export const certificatesProtect = (fileId: string | number): string => {
  return `/build_certificate/${fileId}/protect.json`;
};

export const certificatesDeletePath = (appSlug: string, fileId?: string | number): string => {
  return `/api/app/${appSlug}/build_certificate/${fileId ? `${fileId}/` : ''}delete.json`;
};

export const updateCertificateExposedPath = (fileId: string | number): string => {
  return `/build_certificate/${fileId}/update_is_expose.json`;
};

export const genericFileStoragePath = (appSlug: string): string => {
  return `/api/app/${appSlug}/project_file_storage_document/show.json`;
};

export const genericFileProtect = (fileId: string | number): string => {
  return `/project_file_storage_document/${fileId}/protect.json`;
};

export const genericFileDelete = (appSlug: string, fileId?: 'all' | number): string => {
  return `/api/app/${appSlug}/project_file_storage_document/${
    fileId === 'all' ? 'all_android_keystore_' : `${fileId}/`
  }delete.json`;
};

export const createProvisioningProfilePath = (appSlug: string): string => {
  return `/api/app/${appSlug}/prov_profile_document/create.json`;
};

export const getProvisioningProfilePath = (appSlug: string): string => {
  return `/api/app/${appSlug}/prov_profile_document/show.json`;
};

export const protectProvisioningProfilePath = (id: string | number): string => {
  return `/prov_profile_document/${id}/protect.json`;
};

export const deleteProvisioningProfilePath = (appSlug: string, fileId?: string | number): string => {
  return `/api/app/${appSlug}/prov_profile_document/${fileId ? `${fileId}/` : ''}delete.json`;
};

export const updateProfileExposedPath = (fileId: string | number): string => {
  return `/prov_profile_document/${fileId}/update_is_expose.json`;
};

export const uploadEndedProvisioningProfilePath = (id: string | number): string => {
  return `/prov_profile_document/${id}/upload_ended.json`;
};

export const previewProvisioningProfilePath = (): string => {
  return '/prov_profile_document/preview';
};

export const previewKeystorePath = (): string => {
  return '/key_store/preview';
};

export const detailsProvisioningProfilePath = (id: string | number): string => {
  return `/prov_profile_document/details/${id}`;
};

export const exposeKeystoreMetaPath = (id: string | number): string => {
  return `/project_file_storage_document/${id}/exposed_meta.json`;
};

export const accountAppleConnectionPath = (): string => '/me/account/apple-id';

export const accountFeatureFlagsPath = () => '/me/account/feature-flags';

export const updateFileExposedPath = (fileId: string | number): string => {
  return `/project_file_storage_document/${fileId}/update_is_expose.json`;
};

export const gitHubEnterpriseConnectionPath = (workspaceSlug: string) => {
  return `/organization/${workspaceSlug}/github_app`;
};

export const gitHubEnterpriseConnectionHealthPath = (workspaceSlug: string) => {
  return `/organization/${workspaceSlug}/github_app/connection_health`;
};

export const launchDarklyConnectPath = () => '/release-management/api/launch-darkly/connect';

export const launchDarklyDisconnectPath = () => '/release-management/api/launch-darkly/disconnect';

export const getBuildHtmlReportsPath = (buildSlug: string) => {
  return `/build/${buildSlug}/html-reports`;
};

export const getPipelineHtmlReportsPath = (appSlug: string, pipelineSlug: string, attemptId: string) => {
  return `/app/${appSlug}/pipelines/${pipelineSlug}/attempts/${attemptId}/html-reports`;
};

export const gitHubAppInstallationRedirectPath = (params: URLSearchParams) => {
  return `/github_app_installation/redirect?${params.toString()}`;
};

export const gitHubAppInstallationConnectPath = (params: URLSearchParams) => {
  return `/github_app_installation/connect?${params.toString()}`;
};

export const gitHubAppInstallationConnectAccountPath = (workspaceSlug: string, params: URLSearchParams) => {
  return `/workspaces/${workspaceSlug}/github_account_ownerships?${params.toString()}`;
};

export const deleteGitHubAccountPath = (workspaceSlug: string, gitHubAccountId: string) => {
  return `/organization/${workspaceSlug}/github_accounts/${gitHubAccountId}`;
};

export const getAIAssistanResultPath = (build_slug: string) => {
  return `/api/builds/${build_slug}/ai_assistant_result`;
};

export const postMigrateToGitHubAppIntegrationPath = (appSlug: string) => {
  return `/app/${appSlug}/migrate_to_github_app_integration`;
};

export const postMigrateToGitHubAppTokenAuthorizationPath = (appSlug: string) => {
  return `/app/${appSlug}/migrate_ghes_to_github_app_token_authorization`;
};

export const getCustomConfigPath = (appSlug: string) => {
  return `/app/${appSlug}/custom_config.json`;
};

export const getScanInfoPath = (appSlug: string) => {
  return `/app/${appSlug}/scan_info.json`;
};

export const getAllStackInfoPath = (appSlug: string) => {
  return `/app/${appSlug}/all_stack_info`;
};

export const getMachineTypeConfigPath = (appSlug: string) => {
  return `/app/${appSlug}/machine_type_configs.json`;
};

export const postProjectConfigPath = (appSlug: string) => {
  return `/app/${appSlug}/finish.json`;
};

export const getGitHubAppLinkedRepositoriesPath = (appSlug: string) => {
  return `/app/${appSlug}/settings/integrations/github-app/linkable-repositories`;
};

export const putGitHubAppLinkedRepositoriesPath = (appSlug: string) => {
  return `/app/${appSlug}/settings/integrations/github-app/linked-repositories`;
};

export const putGitHubAppPermissionsPath = (appSlug: string) => {
  return `/app/${appSlug}/settings/integrations/github-app/permissions`;
};
